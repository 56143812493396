'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units.controller:UnitsCtrl

 # @description

###
class UnitsCtrl
  ### @ngInject ###
  constructor:(
    $state
  ) ->
    @currentTab = $state.current.data.selectedTab

angular
  .module('mundoAdmin.units')
  .controller 'UnitsCtrl', UnitsCtrl
